// Returns the current time in hh:mm:ss format.
export function getCurrentTime(): string {
  var dateNow = new Date();
  const hours = String(dateNow.getHours()).padStart(2, "0");
  const minutes = String(dateNow.getMinutes()).padStart(2, "0");
  const seconds = String(dateNow.getSeconds()).padStart(2, "0");

  var timeNow: string;
  timeNow = hours + ":" + minutes + ":" + seconds;
  return timeNow;
}
// Returns the current date in dd/mm/yyyy format.
export function getCurrentDate(): string {
  const currentDate = new Date();

  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = currentDate.getFullYear();

  return `${day}/${month}/${year}`;
}

// Truncates username if it is longer than specified max length.
export function truncateUsername(username: string, maxLength: number): string {
  if (username.length <= maxLength) {
    return username;
  } else {
    return username.substring(0, maxLength) + "...";
  }
}
