/**
 * Component to display landing page content with text and images.
 */
import { Box, Typography } from "@mui/material";
import { palette } from "../../theme";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
type PresentationContainerProps = {
  content: String;
  image: any;
  isLeftImage: boolean;
};

export default function PresentationContainer(
  props: PresentationContainerProps
) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        minHeight: "60vh",
        alignItems: "center",
        width: "70%",
        px: 4,
        borderRadius: "15px",
      }}
    >
      {props.isLeftImage && (
        <img src={props.image} alt="content representation" width={"25%"}></img>
      )}
      <Box
        sx={{
          "@keyframes spin": {
            from: {
              transform: "rotate(0deg)",
            },
            to: {
              transform: "rotate(360deg)",
            },
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          animation: "spin 10s linear infinite",
          ml: props.isLeftImage && 2,
        }}
      >
        <StarBorderPurple500Icon
          sx={{
            fontSize: "36px",
            color: props.isLeftImage ? palette.white : palette.primary_dark,
          }}
        />
      </Box>

      <Box
        sx={{
          flex: 1.5,
          p: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Typography
          sx={{ color: props.isLeftImage && palette.white }}
          variant="h6"
        >
          {props.content}
        </Typography>
      </Box>
      {!props.isLeftImage && (
        <img src={props.image} alt="content representation" width={"25%"}></img>
      )}
    </Box>
  );
}
