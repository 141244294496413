/**
 * Component to display landing page content with text and images.
 */
import { Box, Typography } from "@mui/material";
import { palette } from "../../theme";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
type PresentationContainerProps = {
  content: String;
  image: any;
  isLeftImage: boolean;
};

export default function PresentationContainerMobile(
  props: PresentationContainerProps
) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "60vh",
        alignItems: "center",
        justifyContent: "space-evenly",
        width: "85%",
        borderRadius: "15px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            "@keyframes spin": {
              from: {
                transform: "rotate(0deg)",
              },
              to: {
                transform: "rotate(360deg)",
              },
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            animation: "spin 10s linear infinite",
          }}
        >
          <StarBorderPurple500Icon
            sx={{
              fontSize: "24px",
              color: props.isLeftImage ? palette.white : palette.primary_dark,
            }}
          />
        </Box>

        <Box
          sx={{
            flex: 1.5,
            p: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <Typography
            sx={{
              color: props.isLeftImage && palette.white,
              fontSize: "12px",
              textAlign: "center",
            }}
            variant="h6"
          >
            {props.content}
          </Typography>
        </Box>
      </Box>

      <img src={props.image} alt="content representation" width={"65%"}></img>
    </Box>
  );
}
