/**
 * Context that provides if the user uses mobile phone or not.
 */
import { debounce } from "lodash";
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";

interface MobileContextProps {
  children: ReactNode;
}

type myMobileContextProps = {
  isMobile: boolean;
  isLoading: boolean;
};

const MobileContext = createContext<myMobileContextProps | undefined>(
  undefined
);

export const MobileProvider: React.FC<MobileContextProps> = ({ children }) => {
  const [mobileInformation, setMobileInformation] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (
        isLoading ||
        mobileInformation !== ((window.innerWidth < 768) as boolean)
      ) {
        setIsLoading(true);
        setMobileInformation(window.innerWidth < 768);
        setIsLoading(false);
      }
    }, 600);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileInformation]);

  return (
    <MobileContext.Provider
      value={{ isMobile: mobileInformation, isLoading: isLoading }}
    >
      {children}
    </MobileContext.Provider>
  );
};

export const useMobile = (): myMobileContextProps | undefined => {
  const mobileInformation = useContext(MobileContext);
  if (mobileInformation === undefined) {
    throw new Error("useMobile must be used within a MobileProvider");
  }
  return mobileInformation;
};
