import { createTheme } from "@mui/material/styles";

export const palette = {
  primary: "#0065bd",
  primary_dark: "#03246E",
  secondary: "#00bd65",

  white: "#FFFFFF",
  white2: "#EFEFEF",

  black: "#000000",
  black_light: "#000000DD",

  light_gray1: "#EFEFEF",
  gray1: "#CCCCCC",
  gray2: "#A6A6A6",
  gray3: "#464646",

  light_red: "#FAA0A0",
  red: "#F00000",

  light_green: "#AFE1AF",
  light_green2: "#82CD47",
  green: "#008000",

  light_blue: "#64a0c8",
  light_blue2: "#0065bd22",

  light_yellow: "#FFFDD0",
  yellow1: "#FBEC5D",
  yellow2: "#F4BB44",

  orange: "#F5853F",
};

export const pieChartColors = [
  "#61cdbb",
  "#97e3d5",
  "#e8c1a0",
  "#f47560",
  "#F79862",
  "#FBBF24",
  "#9A66E4",
  "#667EEA",
  "#F472B6",
  "#9CDAE4",
  "#FDBA74",
  "#FBCACA",
  "#B794F4",
  "#BEE3DB",
  "#B8D0E5",
  "#FFADAD",
  "#FFD6A5",
  "#FDFFB6",
  "#CAFFBF",
  "#9BF6FF",
  "#A0C4FF",
  "#FDA7DF",
  "#D4A5A5",
  "#92B06A",
  "#607C8E",
  "#48A6E3",
  "#F58F3D",
  "#2C73D2",
  "#6D597A",
];

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Montserrat",
      textTransform: "none",
    },
  },
});

export default theme;
