import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
const PageNotFound: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "80vh",
        justifyContent: "center",
        alignItems: "center",
        pt: 2,
      }}
    >
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        404 Not Found
      </Typography>
    </Box>
  );
};

export default PageNotFound;
