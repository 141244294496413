/**
 * Components to display page headers.
 */
import {
  Avatar,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { palette } from "../../theme";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchAuthSession } from "aws-amplify/auth";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { truncateUsername } from "../../utils/generals";
import borsayalimLogo from "../../images/borsayalimLogo.svg";

export default function PageHeader() {
  // To click user profile.
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [cursorStyle, setCursorStyle] = useState<string>("pointer");
  // Router information.
  const location = useLocation();
  const navigate = useNavigate();

  // Get current user from authenticator.
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  // Holds idtoken to access user name and email.
  const [userSignInDetails, setUserSignInDetails] = useState(null);

  // Language settings
  const { t } = useTranslation();

  const pages = [
    {
      title: t("components.MY_WALLET"),
      route: "/dashboard",
      guideClassifier: "menu-tabs-my-wallet", // Will be displayed in product tour
    },
    {
      title: t("components.SHARE_TRACKER"),
      route: "/tracker",
      guideClassifier: "menu-tabs-tracker", // Will be displayed in product tour
    },
    {
      title: t("components.SHARE_ANALYSIS"),
      route: "/analysis",
      guideClassifier: "menu-tabs-analysis",
    },
  ];

  // Event handler when user click profile icon.
  const handleClickProfile = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElement(event.currentTarget);
  };

  // Event handler when user click outside of the user profile.
  const handleCloseProfile = () => {
    setAnchorElement(null);
  };

  // Variable to show popup menu.
  const open = Boolean(anchorElement);

  // Event handler when user click logout.
  const handleLogoutRedirect = () => {
    setAnchorElement(null);
    signOut();
    // console.log(location.pathname);
    if (location.pathname === "/") {
      window.location.reload();
    } else {
      navigate("/");
    }
  };

  // Event handler when user click profile.
  const handleProfileClick = () => {
    setAnchorElement(null); // Close menu
    navigate("/profile");
  };

  // Event handlers when mouse hover to logo.
  const handleMouseEnter = () => {
    setCursorStyle("pointer");
  };
  const handleMouseLeave = () => {
    setCursorStyle("default");
  };

  const handleMenuItemClick = (route: string) => {
    navigate(route);
  };

  // Event handler when user click the application logo.
  const handleLogoClick = () => {
    if (userSignInDetails) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    // Get id token of the authenticated user.
    async function currentSession() {
      try {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        // console.log(idToken);
        if (idToken) setUserSignInDetails(idToken.payload);
        else setUserSignInDetails(null);
      } catch (err) {
        setUserSignInDetails(null);
        console.log(err);
      }
    }
    currentSession();
  }, [user]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        py: 1,
        minHeight: "80px",
        background: palette.primary,
      }}
    >
      <div
        style={{
          cursor: cursorStyle.toString(),
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: "8px",
        }}
        onClick={handleLogoClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={borsayalimLogo}
          alt="content representation"
          width={"40px"}
        ></img>
        <Typography
          sx={{
            fontFamily: "Montserrat",
            m: 1,
            fontSize: "18px",
            fontWeight: "bold",
            color: palette.white,
          }}
        >
          borsayalim.com
        </Typography>
      </div>
      <Divider />

      {userSignInDetails && (
        <Box id="menu-tabs" sx={{ ml: 3, flexGrow: 1, display: "flex" }}>
          {pages.map((page) => (
            <Button
              className={page.guideClassifier}
              key={page.route}
              onClick={() => handleMenuItemClick(page.route)}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              {page.title}
            </Button>
          ))}
        </Box>
      )}

      {userSignInDetails && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div onClick={handleClickProfile}>
            <Box
              sx={{
                mr: 1,
                p: 0.7,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ mr: 1, bgcolor: palette.gray2 }}>
                {userSignInDetails ? userSignInDetails["name"][0] : "?"}
              </Avatar>
              <Typography sx={{ color: palette.white }}>
                {userSignInDetails
                  ? truncateUsername(userSignInDetails["name"], 16)
                  : t("errorMessages.USER_NOT_FOUND")}
              </Typography>
              <KeyboardArrowDownIcon sx={{ color: palette.white }} />
            </Box>
          </div>
        </Box>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorElement}
        open={open}
        onClose={handleCloseProfile}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleProfileClick}>
          {t("components.MY_ACCOUNT")}
        </MenuItem>
        <MenuItem onClick={handleLogoutRedirect}>
          {t("components.LOG_OUT")}
        </MenuItem>
      </Menu>
    </Box>
  );
}
