import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import ShareStatistics from "./pages/ShareStatistics";
import { SnackbarProvider } from "notistack";
import PageLayout from "./components/Layouts/PageLayout";
import { Routes, Route } from "react-router-dom";
import Welcome from "./pages/Welcome";
import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Auth from "./pages/Auth";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import trTranslation from "./locales/tr/translation.json";
import AboutUs from "./pages/AboutUs";
import Privacy from "./pages/Privacy";
import ShareTracker from "./pages/ShareStatistics/ShareTracker";
import ShareAnalysis from "./pages/ShareStatistics/ShareAnalysis";
import AuthHOC from "./components/Auth/AuthHOC";
import { useMobile } from "./components/Contexts/MobileContext";
import ShareTrackerMobile from "./pages/ShareStatistics/ShareTrackerMobile";
import ShareAnalysisMobile from "./pages/ShareStatistics/ShareAnalysisMobile";
import WelcomeMobile from "./pages/WelcomeMobile";
import UserProfile from "./pages/UserProfile";
import UserProfileMobile from "./pages/UserProfileMobile";
import ScrollToTop from "./helpers/ScrollToTop";
import DashboardMobile from "./pages/DashboardMobile";
import Dashboard from "./pages/Dashboard";
import PageNotFound from "./pages/PageNotFound";

i18n.use(initReactI18next).init({
  resources: {
    tr: { translation: trTranslation },
  },
  lng: "tr", // Set the default language
  interpolation: {
    escapeValue: false, // React already protects from XSS
  },
});

const Pages = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const mobileInformation = useMobile();

  return (
    <Routes>
      {/* <Route path="/sharestatistics" element={<ShareStatistics />} /> */}
      {/*<Route
        path="/dashboard"
        Component={
          mobileInformation.isMobile
            ? AuthHOC(DashboardMobile, user !== undefined && user !== null)
            : AuthHOC(Dashboard, user !== undefined && user !== null)
        }
      />
      <Route
        path="/tracker"
        Component={
          mobileInformation.isMobile
            ? AuthHOC(ShareTrackerMobile, user !== undefined && user !== null)
            : AuthHOC(ShareTracker, user !== undefined && user !== null)
        }
      />
      <Route
        path="/analysis"
        Component={
          mobileInformation.isMobile
            ? AuthHOC(ShareAnalysisMobile, user !== undefined && user !== null)
            : AuthHOC(ShareAnalysis, user !== undefined && user !== null)
        }
      />
      <Route
        path="/profile"
        Component={
          mobileInformation.isMobile
            ? AuthHOC(UserProfileMobile, user !== undefined && user !== null)
            : AuthHOC(UserProfile, user !== undefined && user !== null)
        }
      />
      */}
      <Route path="/auth" element={<Auth />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route
        path="/"
        element={mobileInformation.isMobile ? <WelcomeMobile /> : <Welcome />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Authenticator.Provider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider autoHideDuration={3000} maxSnack={3}>
            <PageLayout>
              <Pages />
            </PageLayout>
          </SnackbarProvider>
        </LocalizationProvider>
      </Authenticator.Provider>
    </Router>
  );
};

export default App;
