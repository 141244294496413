/**
 * General pagelayout wrapper for the application.
 */
import {
  AppBar,
  Box,
  CircularProgress,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import Footer from "./Footer";
import PageHeader from "./PageHeader";
import { useMobile } from "../Contexts/MobileContext";
import PageHeaderMobile from "./PageHeaderMobile";
import { useTranslation } from "react-i18next";
import Disclaimer from "./Disclaimer";
import useScrollTrigger from "@mui/material/useScrollTrigger";

function HideOnScroll(props: any) {
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {props.children}
    </Slide>
  );
}

export default function PageLayout(props: any) {
  const mobileInformation = useMobile();
  // Language settings
  const { t } = useTranslation();
  return (
    <Box
      className="App"
      sx={{
        width: "100%",
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {mobileInformation.isLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "90vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            <Typography sx={{ mt: 1 }}>{t("components.LOADING")}</Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <HideOnScroll>
            <AppBar sx={{ width: "100%" }}>
              {mobileInformation.isMobile ? (
                <PageHeaderMobile />
              ) : (
                <PageHeader />
              )}
            </AppBar>
          </HideOnScroll>
          <Toolbar />
          <Box
            sx={{
              mt: 2,
              width: "100%",
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {props.children}
          </Box>
        </Box>
      )}
      <Disclaimer />
      <Footer />
    </Box>
  );
}
