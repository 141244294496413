/**
 * Why us section of the welcome page. It is a mobile view.
 */
import { Box, IconButton, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { palette } from "../../theme";
import selectImage from "../../images/shareTrack.svg";
import checkAnalysesGif from "../../images/checkAnalyses.gif";
import controlGif from "../../images/control.gif";
import PresentationContainerMobile from "./PresentationContainerMobile";
import SouthIcon from "@mui/icons-material/South";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Confetti from "react-confetti";

type compProps = {
  scrollNext: Function; // Get the event handler when this section is done, scroll down to the next one.
};
export default function WhyUsMobile(props: compProps) {
  // Variables to use confetti effect.
  const [coordinates, setCoordinates] = useState({ x: 20, y: 20 });
  const [runConfetti, setRunConfetti] = useState(false);
  // Next steps in scrolling the page.
  const secondStepComponent = useRef<HTMLDivElement>(null);
  const thirdStepComponent = useRef<HTMLDivElement>(null);

  // Event handler when user click the next buttons.
  const handleScrollDown = (componentRef: any) => {
    // Check if the ref is available before scrolling
    if (componentRef && componentRef.current) {
      componentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  // Event handler when user finish the how it works section.
  const handleEndIconClick = (event: React.MouseEvent) => {
    // Set the coordinates where the confetti will be show up.
    setCoordinates({
      x: event.pageX,
      y: event.pageY,
    });
    setRunConfetti(true);
    // After 1 sec scroll to the next view.
    setTimeout(() => {
      props.scrollNext();
    }, 1200);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: palette.black_light,
          py: 4,
        }}
      >
        <Typography
          sx={{
            color: palette.white,
            fontWeight: 500,
            fontSize: "24px",
          }}
        >
          İşte Neler Yapabilirsin!
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: palette.white,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: palette.white,
            py: 4,
          }}
        >
          <Typography
            sx={{
              color: palette.black_light,
              fontWeight: 500,
              fontSize: "24px",
            }}
          >
            1- Paranın akışını takip et
          </Typography>
          <PresentationContainerMobile
            isLeftImage={false}
            image={selectImage}
            content={
              "Hisse alım/satım işlemlerinizi kaydedin. Hisselerinizin zaman içerisinde portföyünüze ne kazandırdığını detaylı bir şekilde gözlemleyin."
            }
          />
          <Box
            sx={{
              mt: 2,
              backgroundColor: palette.light_gray1,
              borderRadius: "30px",
            }}
          >
            <IconButton onClick={() => handleScrollDown(secondStepComponent)}>
              <SouthIcon
                sx={{ fontSize: "48px", color: palette.black_light }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: palette.primary,
            py: 4,
          }}
        >
          <Typography
            sx={{
              color: palette.white,
              fontWeight: 500,
              fontSize: "24px",
              mt: 2,
            }}
          >
            2- Analizleri kontrol et
          </Typography>
          <PresentationContainerMobile
            isLeftImage={true}
            image={checkAnalysesGif}
            content={
              "Portföyünüzü her açıdan inceleyerek günlük, haftalık ve aylık kâr-zarar dengenizi gözden geçirin. Uzun vadeli analizlerle alım-satım stratejinizi mükemmelleştirin ve risk yönetiminizi en üst seviyeye çıkarın!"
            }
          />
          <Box
            sx={{
              mt: 2,
              backgroundColor: palette.light_gray1,
              borderRadius: "30px",
            }}
          >
            <IconButton onClick={() => handleScrollDown(thirdStepComponent)}>
              <SouthIcon
                sx={{ fontSize: "48px", color: palette.black_light }}
              />
            </IconButton>
          </Box>
        </Box>
        <div ref={secondStepComponent} />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: palette.white,
            py: 4,
          }}
        >
          <Typography
            sx={{
              color: palette.black_light,
              fontWeight: 500,
              fontSize: "24px",
              mt: 2,
            }}
          >
            3- Kontrolü eline al
          </Typography>
          <PresentationContainerMobile
            isLeftImage={false}
            image={controlGif}
            content={
              "Yatırım kararlarınıza ışık tutmak için size özel hazırlanmış geçmiş portföy analizlerini detaylı bir şekilde inceleyin."
            }
          />
          <Box>
            <IconButton onClick={handleEndIconClick}>
              <CheckCircleOutlineIcon
                sx={{ fontSize: "64px", color: palette.primary }}
              />
            </IconButton>
          </Box>
        </Box>
        <div ref={thirdStepComponent} />
      </Box>
      <Confetti
        confettiSource={{
          x: coordinates.x,
          y: coordinates.y,
          h: 20,
          w: 20,
        }}
        recycle={false}
        onConfettiComplete={() => {
          setRunConfetti(false);
        }}
        run={runConfetti}
        width={window.innerWidth}
        height={coordinates.y + 100}
        numberOfPieces={500}
      />
    </Box>
  );
}
