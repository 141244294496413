/**
 * Authentication page of the users.
 */
import React from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import awsmobile from "..//aws-exports";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Navigate, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
import { trDict } from "../lang/auth-tr";

// Set main language as turkish.
I18n.putVocabularies(translations);
I18n.setLanguage("tr");
I18n.putVocabularies({
  tr: trDict,
});

// Configure amplify auth to introduce cognito user pools and client.
Amplify.configure(awsmobile);

export default function Auth() {
  // Hold the authentication state. "signIn", "signOut", "authenticated" etc...
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();

  // Get redirect location or provide fallback
  const from = location.state?.from || "/dashboard";
  // If authentication successfull, navigate to the main page.
  return route === "authenticated" ? (
    <Navigate to={from} />
  ) : (
    <Box sx={{ pt: 1, height: "80vh" }}>
      <Authenticator
        loginMechanisms={["email"]}
        signUpAttributes={["email", "name"]}
      />
    </Box>
  );
}
