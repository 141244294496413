import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { MobileProvider } from "./components/Contexts/MobileContext";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";

document.addEventListener("DOMContentLoaded", () => {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    // <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MobileProvider>
        <App />
      </MobileProvider>
    </ThemeProvider>
    // </React.StrictMode>
  );
});
