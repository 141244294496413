/**
 * Components to display page headers. It is for mobile access
 */
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect, useState } from "react";
import { palette } from "../../theme";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchAuthSession } from "aws-amplify/auth";
import LoopIcon from "@mui/icons-material/Loop";
import BarChartIcon from "@mui/icons-material/BarChart";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { truncateUsername } from "../../utils/generals";
import borsayalimLogo from "../../images/borsayalimLogo.svg";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

export default function PageHeaderMobile() {
  // To click user profile.
  // const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  // Router information.
  const location = useLocation();
  const navigate = useNavigate();

  // Get current user from authenticator.
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  // Holds idtoken to access user name and email.
  const [userSignInDetails, setUserSignInDetails] = useState(null);

  // Drawer controller
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  // Language settings
  const { t } = useTranslation();

  // Event handler when user close the drawer.
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  // Event handler when user open the drawer.
  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  // // Event handler when user click profile icon.
  // const handleClickProfile = (event: React.MouseEvent<HTMLDivElement>) => {
  //   setAnchorElement(event.currentTarget);
  // };

  // Event handler when user click logout.
  const handleLogoutRedirect = () => {
    // setAnchorElement(null);
    signOut();
    // console.log(location.pathname);
    if (location.pathname === "/") {
      window.location.reload();
    } else {
      navigate("/");
      setDrawerOpen(false);
    }
  };

  // Event handler when user click profile.
  const handleProfileClick = () => {
    navigate("/profile");
    setDrawerOpen(false);
  };

  // Event handler when user click the application logo.
  const handleLogoClick = () => {
    if (userSignInDetails) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    // Get id token of the authenticated user.
    async function currentSession() {
      try {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        // console.log(idToken);
        if (idToken) setUserSignInDetails(idToken.payload);
        else setUserSignInDetails(null);
      } catch (err) {
        setUserSignInDetails(null);
        console.log(err);
      }
    }
    currentSession();
  }, [user]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        // justifyContent: "space-between",
        alignItems: "center",
        pt: 1,
        pb: 1,
        minHeight: "75px",
        background: palette.primary,
      }}
    >
      {userSignInDetails && (
        <Box className="menu-button-drawer">
          <IconButton onClick={handleOpenDrawer}>
            <MenuIcon sx={{ color: palette.white }} />
          </IconButton>
        </Box>
      )}
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
        onClick={handleLogoClick}
      >
        <img src={borsayalimLogo} alt="content representation" width={"30px"} />
        <Typography
          sx={{
            m: 1,
            fontSize: "16px",
            fontWeight: "bold",
            color: palette.white,
          }}
        >
          borsayalim.com
        </Typography>
      </div>

      <Drawer anchor="left" open={drawerOpen} onClose={handleCloseDrawer}>
        <Box sx={{ width: 250, height: "100%" }} role="presentation">
          <Box
            sx={{
              backgroundColor: palette.primary,
              p: 1,
              minHeight: "75px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              src={borsayalimLogo}
              alt="content representation"
              width={"30px"}
            ></img>
            <Typography
              sx={{ ml: 1, color: palette.white, fontWeight: "bold" }}
            >
              borsayalim.com
            </Typography>
          </Box>
          {userSignInDetails && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  mr: 1,
                  p: 0.7,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ mr: 1, bgcolor: palette.gray2 }}>
                  {userSignInDetails ? userSignInDetails["name"][0] : "?"}
                </Avatar>
                <Typography sx={{ color: palette.black }}>
                  {userSignInDetails
                    ? truncateUsername(userSignInDetails["name"], 16)
                    : t("errorMessages.USER_NOT_FOUND")}
                </Typography>
              </Box>
            </Box>
          )}
          <Divider />
          <Box sx={{ height: "70vh" }}>
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate("/dashboard");
                    setDrawerOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <AccountBalanceWalletIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("components.MY_WALLET")} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate("/tracker");
                    setDrawerOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <LoopIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("components.SHARE_TRACKER")} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate("/analysis");
                    setDrawerOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <BarChartIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("components.SHARE_ANALYSIS")} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>

          <Box sx={{ flex: 1, flexGrow: 1 }} />
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <Divider />
            <List>
              <ListItem disablePadding>
                <ListItemButton onClick={handleProfileClick}>
                  <ListItemIcon>
                    <AccountBoxIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("components.MY_ACCOUNT")} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={handleLogoutRedirect}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("components.LOG_OUT")} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
