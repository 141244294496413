import { Box, Typography } from "@mui/material";
import React from "react";
import { useMobile } from "../Contexts/MobileContext";

export default function Disclaimer() {
  const mobileInformation = useMobile();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
      <Typography
        sx={{
          fontSize: mobileInformation.isMobile ? "8px" : "10px",
          textAlign: "justify",
        }}
      >
        Yasal Uyarı: Piyasa verileri kamuya açık üçüncü taraf veri sağlayıcıları
        tarafından sağlanmaktadır. Hisse senedi verileri 15-30 dakikaya kadar
        gecikmeli olabilmektedir. Burada hiçbir şekilde yatırım tavsiyesi
        niteliğinde bir açıklamaya yer verilmemiştir. borsayalim.com üzerinde
        düzenlenen grafikler mali durumunuz ile risk ve getiri tercihlerinize
        uygun olmayabilir. Bu nedenle, sadece burada yer alan bilgilere
        dayanılarak yatırım kararı verilmesi beklentilerinize uygun sonuçlar
        doğurmayabilir. Gerek site üzerindeki, gerekse site için kullanılan
        kaynaklardaki hata ve eksikliklerden ve sitedeki bilgilerin kullanılması
        sonucunda yatırımcıların uğrayabilecekleri doğrudan ve/veya dolaylı
        zararlardan, kar yoksunluğundan, manevi zararlardan ve üçüncü kişilerin
        uğrayabileceği zararlardan borsayalim.com hiçbir şekilde sorumlu
        tutulamaz. BİST isim ve logosu "Koruma Marka Belgesi" altında korunmakta
        olup izinsiz kullanılamaz, iktibas edilemez, değiştirilemez. BİST ismi
        altında açıklanan tüm bilgilerin telif hakları tamamen BİST'e ait olup,
        tekrar yayınlanamaz.
      </Typography>
    </Box>
  );
}
