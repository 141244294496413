import React from "react";
import Typography from "@mui/material/Typography";
import { Button, Box } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";

const Privacy: React.FC = () => {
  const navigate = useNavigate();

  const onMainPageClick = () => {
    navigate("/");
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        pt: 2,
        pl: 4,
        pr: 4,
      }}
    >
      <Typography variant="h2" gutterBottom>
        Gizlilik ve Çerez Politikası
      </Typography>

      <Typography variant="h4">1. Genel</Typography>
      <Typography variant="body1" paragraph>
        Bundan böyle “Borsayalım” olarak anılacak www.borsayalim.com, web
        sitesini ziyaret edenlerin gizliliğini korumayı ilke olarak
        benimsemiştir. Bu kapsamda Borsayalım olarak, kişisel verilerin hukuka
        uygun işlenmesi adına 6698 sayılı Kişisel Verilerin Korunması
        Kanunu("KVKK") hükümlerine, Kişisel Verileri Koruma Kurulu kararlarına
        ve uygun düştüğü ölçüde Avrupa Birliği Veri Koruma Tüzüğü(“GDPR“)
        hükümlerine uyum sağlamak için gerekli her türlü tedbir ve aksiyonu
        alıyoruz.Sizleri, kullanıcılara ilişkin ne tür bilgilerin Borsayalım
        tarafından işlendiğini ve bunların nasıl kullanıldığı konusunda
        bilgilendirmek istiyoruz. Aynı zamanda sizleri, veri sahibi olarak
        ilgili haklarınızı nasıl kullanabileceğinize dair bilgilendireceğiz.
        İşbu Gizlilik ve Çerez Politikası‘na ilişkin olası değişiklikler bu
        sayfada yayımlanacaktır. Bu çerçevede, hangi bilgilerin saklandığına ve
        bu bilgilerin nasıl kullanıldığına dair güncel olarak bilgi almanıza
        olanak sağlanacaktır. İşbu Gizlilik ve Çerez Politikası,
        www.borsayalim.com sitesinden bağlantı uzantısı verilmiş başka web
        siteleri için geçerli değildir.
      </Typography>

      <Typography variant="h4" gutterBottom>
        2. Kişisel verilerin toplanması, kullanımı ve işlenmesi
      </Typography>
      <Typography variant="body1" paragraph>
        Borsayalım, ilgili kişisel verileri bu web sitesini kullanıcılara sunmak
        ve bu sitenin uygun şekilde çalıştığının ve gerekli ölçüde güvenliğin
        sağlandığının temin edilmesi doğrultusunda kullanır. Bu kapsam dışında
        kalan ve veriler üzerinde gerçekleştirilen her işlem, diğer yasal
        yükümlülükler, izinler, Borsayalım’ın meşru menfaatinin bulunduğu
        durumlar zemininde ya da ilgili kullanıcının verdiği açık rıza
        çerçevesinde gerçekleşmektedir.Borsayalım, ilgili verileri özel olarak
        şu amaçlar doğrultusunda saklar ve işler: Kullanıcılar web sitesini
        ziyaret ettiğinde Borsayalım belirli verileri otomatik olarak toplar ve
        saklar. Bu verilere: Talep edilen içeriği(örn. özellikle içeriği,
        metinleri, görselleri ve indirme için sağlanan veri dosyalarını vb.)
        iletebilmek için gerek duyulan, ilgili uç cihaza tahsis edilmiş IP
        adresi ya da cihaz kimliği, kullanıcıların web sitesi bağlamındaki
        aktiviteleri, ilgili uç cihaz türü, kullanılan tarayıcı türü ve kullanım
        tarihi ve saati dahildir. Borsayalım bu bilgileri amaç dışında kullanımı
        tespit ve takip edebilmek üzere belirli bir süreyle saklamaktadır. Aynı
        zamanda Borsayalım, hizmetlerin sunumunu, özelliklerini, işlevlerini ve
        genel yönetim görevlerini geliştirmek için de bu bilgileri kullanır.
        Bunların yanında Borsayalım, yukarıda verilen amaçlar doğrultusunda söz
        konusu verilere artık gerek duyulmadığında, IP adresleri de dahil olmak
        üzere kullanımdaki verileri usule aykırı gecikme olmaksızın siler ya da
        anonim hale getirir. İlgili verilerin işlenmesi ve kullanımı,(1)
        işlemenin web sitesinin sunulabilmesi için gerekli olduğu; ya da(2)
        Borsayalım’ın, web sitesinin işlevselliğini ve sorunsuz biçimde
        çalışmasını, aynı zamanda da kullanıcıların gereksinimlerine uygun
        olmasını temin eden ve geliştiren meşru bir menfaate sahip olduğu
        durumlarda, bu işlemlerin yapılabilmesine imkan sağlayan ilgili yasa
        hükümlerinin oluşturduğu zemin doğrultusunda gerçekleştirilmektedir.
      </Typography>
      <Typography variant="h4" gutterBottom>
        3. Kişisel Verilerin İşlenme Amaçları
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>İletişim(Bize Ulaşın) / Müşteri Talep Formu</strong> İletişim
        bilgilerinizi web sitemizde yer alan{" "}
        <a href="mailto:borsayalim@hotmail.com">"Bize Ulaşın"</a> isimli
        iletişim formu üzerinden bizimle paylaşmış olabilirsiniz. Size ait
        verileri talebinize cevap vermek amacıyla kullanacağız. Verilerinizin
        işlenmesi ve kullanımı, mevcut talebinizi karşılamak için işlemenin
        gerekli olması halinde bunun yapılabilmesine imkan sağlayan yasa
        hükümlerinin ve/veya Borsayalım’ın meşru menfaatinin oluşturduğu zemin
        doğrultusunda gerçekleştirilmektedir. Herhangi bir açık rıza beyanı
        vasıtasıyla daha uzun bir süre için onay vermediğiniz durumda, verileri
        yalnızca yukarıda verilen hedefi gerçekleştirmek için gerekli süre ya da
        herhangi bir yasal saklama zorunluluğunun gerektirdiği dönem boyunca
        saklayacağız.
      </Typography>
      <Typography variant="h4" gutterBottom>
        4. Çerezler ("Cookie"ler), Pikseller, ve benzer teknolojiler
      </Typography>
      <Typography variant="body1" paragraph>
        Bu web sitesi, çerezleri ve benzeri teknolojileri kullanmaktadır.
        Çerezler, web sitemizi kullanmak için gerekli olan ve tarayıcınız
        tarafından sabit diskinize geçici olarak depolanan küçük veri
        birimleridir. Bir çerez genellikle, rastgele şekilde oluşturulmuş bir
        sayıdan oluşan ve cihazınıza depolanan benzersiz bir tanımlayıcı içerir.
        Bu tanımlayıcıların bazıları web sitesi oturumunuz sona erdiğinde
        geçerliliğini yitirirken, bazıları ise daha uzun süreler boyunca
        cihazınızda kalabilir. Teknik açıdan gerekli çerezler otomatik olarak
        kullanılacaktır. Diğer çerezler(ya da benzeri teknolojiler) yalnızca ön
        onayınız üzerine uygulanacaktır. Farklı türde çerezler bulunmaktadır.
        Birinci taraf çerezleri, ziyaret edilen web sitesi tarafından
        yerleştirilir ve yalnızca o web sitesi tarafından okunabilir. Üçüncü
        taraf çerezleri ise, kullandığımız çeşitli servisler için hizmet
        aldığımız diğer kuruluşlar tarafından yerleştirilmektedir. Buna örnek
        olarak, web sitemizde beğenilen ya da beğenilmeyen içerikleri tespit
        edebilmek amacıyla bizim adımıza çerezler yerleştiren harici analitik
        hizmeti sağlayıcıları kullanıyoruz. Buna ek olarak, ziyaret ettiğiniz
        web sitesi, örneğin, YouTube gibi bir kaynaktan alınmış gömülü bir
        içerik barındırabilir ve çerezler bu kaynak tarafından da
        yerleştirilebilir. Bir piksel genellikle, içerik kişiselleştirme ya da
        tarayıcı deneyimini daha etkili ve kolay hâle getirme amacı
        doğrultusunda bir web sitesine yerleştirilen ve IP adresi, tarayıcı
        türü, erişim zamanı ya da önceden ayarlanan çerezler gibi belirli
        verileri toplayan yalnızca 1 piksel x 1 piksel ölçülerindeki şeffaf bir
        görseldir. Piksellerin çerezleri oluşturup yönetmesi nedeniyle sıklıkla
        çerezlerle birlikte kullanılırlar. Web sitesi, onay vermeniz halinde
        cihazın işletim sistemi, tarayıcısı, yüklü yazı tipleri, IP adresi,
        eklentileri ve daha detaylı bilgileri toplayacaktır. Bu sayede cihaz,
        kullanıcıya ilişkin pazarlama amaçları doğrultusunda yeniden
        tanınabilir.
      </Typography>

      <Box
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <Button
          onClick={onMainPageClick}
          sx={{ mt: 2 }}
          variant="contained"
          startIcon={<HomeIcon />}
        >
          Anasayfa
        </Button>
      </Box>
    </Box>
  );
};

export default Privacy;
