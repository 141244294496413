/**
 * Welcome page of the application. It is a mobile view.
 */
import { Box, Button, Typography, IconButton } from "@mui/material";
import { palette } from "../theme";
import backgroundImage from "../images/backgroundGradient.svg";
import SouthIcon from "@mui/icons-material/South";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HowItWorksMobile } from "./Welcome/HowItWorksMobile";
import WhyUsMobile from "./Welcome/WhyUsMobile";
export default function WelcomeMobile() {
  // Reference to scroll component when chick explore button.
  const firstStepComponent = useRef<HTMLDivElement>(null);
  // Reference to scroll why us part of the welcome page.
  const whyUsRef = useRef<HTMLDivElement>(null);
  // Reference to scroll start button at the bottom of the page.
  const startButtonRef = useRef<HTMLDivElement>(null);

  // Navigator
  const navigate = useNavigate();

  // Language settings
  const { t } = useTranslation();

  // Event handler when user wants to login
  const handleLoginRedirect = () => {
    navigate("/auth");
  };

  // When user click explore, scroll down the page to the presentation of application.
  const handleScrollDown = () => {
    // Check if the ref is available before scrolling
    if (firstStepComponent && firstStepComponent.current) {
      firstStepComponent.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  // When user finish "how it works" section, scroll to the next section "why us?"
  const handleScrollDownWhyUs = () => {
    // Check if the ref is available before scrolling
    if (whyUsRef && whyUsRef.current) {
      whyUsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  // When user finish "why us" section, scroll to the start button on the bottom of the page.
  const handleScrollDownStartButton = () => {
    // Check if the ref is available before scrolling
    if (startButtonRef && startButtonRef.current) {
      startButtonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            width: "100%",
            minHeight: "92vh",
            height: "100%",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
          }}
        >
          <Box
            sx={{
              p: 2,
              flex: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderRadius: "8px",
                height: "85%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: palette.white,
                    fontSize: "26px",
                    fontWeight: 500,

                    "@media screen and (max-width: 300px)": {
                      fontSize: "18px",
                    },
                  }}
                >
                  Borsayalim ile Finansal Başarıya Adım Atın!
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: palette.white,
                    mt: 2,
                    fontSize: "16px",
                  }}
                >
                  Borsada yatırım yaparken kontrol sizin elinizde! Finansal
                  hedeflerinize ulaşmanız için borsayalim.com yanınızda!
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    justifyContent: "space-evenly",
                  }}
                >
                  <Box />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: palette.white,
                        fontSize: "32px",
                        textAlign: "center",
                      }}
                    >
                      Çok yakında mobil platformda sizlerle!
                    </Typography>
                  </Box>
                  {/*<Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Button
                      variant="contained"
                      endIcon={<NavigateNextIcon />}
                      onClick={handleLoginRedirect}
                      sx={{
                        borderRadius: "30px",
                        fontWeight: 600,
                        py: 1,
                        fontSize: "16px",
                        background:
                          "linear-gradient(45deg, #0065bd 30%, #00bd65 90%)",
                      }}
                    >
                      {t("components.REGISTER_NOW")}
                    </Button>
                    <Button
                      onClick={handleLoginRedirect}
                      sx={{
                        mt: 1,
                        borderRadius: "30px",
                        fontWeight: 500,
                        py: 0.5,
                        fontSize: "14px",
                        color: palette.white,
                      }}
                    >
                      {t("components.LOGIN")}
                    </Button>
                    </Box>*/}
                  <Box />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        p: 1,
                        fontSize: "16px",
                        fontWeight: 500,
                        color: palette.white,
                      }}
                    >
                      Şimdi keşfedin!
                    </Typography>
                    <Box textAlign="center">
                      <IconButton
                        sx={{ mt: 1, backgroundColor: palette.gray3 }}
                        size="large"
                        color="primary"
                        onClick={handleScrollDown}
                      >
                        <SouthIcon
                          fontSize="inherit"
                          sx={{ color: palette.white }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <HowItWorksMobile
          ref={firstStepComponent}
          scrollNext={handleScrollDownWhyUs}
        />
        <div ref={whyUsRef} />

        <WhyUsMobile scrollNext={handleScrollDownStartButton} />
        {/**                
        <Box
          sx={{
            mb: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            endIcon={<NavigateNextIcon />}
            onClick={handleLoginRedirect}
            sx={{
              borderRadius: "30px",
              fontWeight: 600,
              py: 1,
              px: 5,
              fontSize: "16px",
              background: "linear-gradient(45deg, #0065bd 30%, #00bd65 90%)",
            }}
          >
            {t("components.START")}
          </Button>
        </Box>*/}
        <div ref={startButtonRef} />
      </Box>
    </Box>
  );
}
