/**
 * How it works section of the welcome page. It is a desktop view.
 */
import { Box, IconButton, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { palette } from "../../theme";
import SouthIcon from "@mui/icons-material/South";
import toggleGif from "../../images/toggle.gif";
import analysisGif from "../../images/analysis.gif";
import signupGif from "../../images/signup.gif";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Confetti from "react-confetti";

type DivProps = {
  ref: React.HTMLProps<HTMLDivElement>; // Get the ref object from the parent and assign first element in this component.
  scrollNext: Function; // Get the event handler when this section is done, scroll down to the next one.
};

const HowItWorks = React.forwardRef<HTMLDivElement, DivProps>((props, ref) => {
  // Variables to use confetti effect.
  const [coordinates, setCoordinates] = useState({ x: 20, y: 20 });
  const [runConfetti, setRunConfetti] = useState(false);
  // Next steps in scrolling the page.
  const secondStepComponent = useRef<HTMLDivElement>(null);
  const thirdStepComponent = useRef<HTMLDivElement>(null);

  // Event handler when user click the next buttons.
  const handleScrollDown = (componentRef: any) => {
    // Check if the ref is available before scrolling
    if (componentRef && componentRef.current) {
      componentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  // Event handler when user finish the how it works section.
  const handleEndIconClick = (event: React.MouseEvent) => {
    // Set the coordinates where the confetti will be show up.
    setCoordinates({
      x: event.pageX,
      y: event.pageY,
    });
    setRunConfetti(true);
    // After 1 sec scroll to the next view.
    setTimeout(() => {
      props.scrollNext();
    }, 1200);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "48px",
            fontWeight: 400,
            py: 4,
            color: palette.black_light,
          }}
        >
          Nasıl Çalışır?
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: palette.white,
          p: 2,
          py: 4,
          minHeight: "50vh",
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
          1. Öncelikle bir hesap oluştur ve borsayalim.com ayrıcalıklarından
          faydalanmaya başla!
        </Typography>
        <img src={signupGif} alt="content representation" width={"25%"}></img>
        <Box
          sx={{
            mt: 4,
            backgroundColor: palette.light_gray1,
            borderRadius: "30px",
          }}
        >
          <IconButton onClick={() => handleScrollDown(secondStepComponent)}>
            <SouthIcon sx={{ fontSize: "48px", color: palette.black_light }} />
          </IconButton>
        </Box>
      </Box>
      <div ref={ref}></div>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: palette.primary,
          p: 2,
          py: 4,
          minHeight: "50vh",
        }}
      >
        <Typography
          sx={{ fontSize: "18px", color: "white", fontWeight: "500" }}
        >
          2. Analizlerini görmek istediğin hisse ve lot sayılarını portföyüne
          ekle!
        </Typography>
        <img src={toggleGif} alt="content representation" width={"25%"}></img>
        <Box
          sx={{
            mt: 4,
            backgroundColor: palette.light_gray1,
            borderRadius: "30px",
          }}
        >
          <IconButton onClick={() => handleScrollDown(thirdStepComponent)}>
            <SouthIcon
              sx={{
                fontSize: "48px",
                color: palette.primary,
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <div ref={secondStepComponent} />

      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: palette.white,
          p: 2,
          py: 4,
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            color: palette.black_light,
            fontWeight: "500",
          }}
        >
          3. Artık borsayalim.com analizlerini incelemeye hazırsın!
        </Typography>
        <img src={analysisGif} alt="content representation" width={"25%"}></img>
        <Box sx={{ mt: 4 }}>
          <IconButton onClick={handleEndIconClick}>
            <CheckCircleOutlineIcon
              sx={{ fontSize: "64px", color: palette.primary }}
            />
          </IconButton>
        </Box>
      </Box>
      <div ref={thirdStepComponent} />
      <Confetti
        confettiSource={{
          x: coordinates.x,
          y: coordinates.y,
          h: 20,
          w: 20,
        }}
        recycle={false}
        onConfettiComplete={() => {
          setRunConfetti(false);
        }}
        run={runConfetti}
        width={window.innerWidth}
        height={coordinates.y + 100}
        numberOfPieces={500}
      />
    </Box>
  );
});

export { HowItWorks };
