import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
const AboutUs: React.FC = () => {
  const navigate = useNavigate();

  const onMainPageClick = () => {
    navigate("/");
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        p: 2,
        pl: 4,
        pr: 4,
      }}
    >
      <Typography variant="h2" gutterBottom>
        Biz Kimiz?
      </Typography>

      <Typography variant="body1" paragraph>
        <strong>borsayalim.com</strong> olarak, Aralık 2023 tarihinde kurulan ve
        finansal başarıyı hedefleyen bir girişimiz. Vizyonumuz, finansal
        bilincin gücünü herkesle paylaşarak, borsa kullanıcılarının
        sermayelerini yönetmelerini sağlayan lider bir platform olmaktır.
      </Typography>

      <Typography variant="h4" gutterBottom>
        Misyonumuz
      </Typography>

      <Typography variant="body1" paragraph>
        Misyonumuz, borsa kullanıcılarına finansal başarıya ulaşmalarında
        yardımcı olmak ve sermayelerini etkili bir şekilde yönetmelerini
        sağlamaktır. Kullanıcılarımıza, kullanıcı dostu bir arayüzde, güçlü
        analitik araçlar ve detaylı portföy takibi sunarak, yatırımlarını en iyi
        şekilde değerlendirmelerine katkıda bulunuyoruz. Müşteri memnuniyeti
        odaklı yaklaşımımızla, finansal hedeflerine ulaşmalarına rehberlik
        ediyor, bilinçli ve güvenilir bir yatırım deneyimi sunuyoruz.
        Vizyonumuzu gerçekleştirmek için sürekli olarak yenilikçi çözümler
        geliştirir ve borsa kullanıcılarının finansal geleceklerini
        parlaklaştırmak için çabalıyoruz.
      </Typography>

      {/* <Typography variant="h4" gutterBottom>
        Ekibimiz
      </Typography>

      <Typography variant="body1" paragraph>
        Biz, 3 kişilik bir ekibiz ve her birimiz alanında uzmanlaşmış deneyimli
        profesyonelleriz. Bir araya gelerek,
        müşterilerimize/yatırımcılarımıza/kullanıcılarımıza en kaliteli hizmeti
        ve ürünleri sunmayı amaçlıyoruz.
      </Typography> */}

      <Typography variant="h4" gutterBottom>
        Değerlerimiz
      </Typography>

      <ul>
        <li>
          <Typography variant="body1">
            <strong>Müşteri Memnuniyeti:</strong> Her zaman müşterilerimizin
            ihtiyaçlarına odaklanır ve onlara en iyi hizmeti sunmaya çalışırız.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>İnovasyon:</strong> Sektördeki gelişmeleri takip eder ve
            sürekli olarak yeni ve yaratıcı çözümler geliştirmeye odaklanırız.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            <strong>Şeffaflık:</strong> Her zaman şeffaf ve dürüst bir iletişim
            anlayışını benimseriz, müşterilerimizle ve iş ortaklarımızla açık
            bir diyalog sürdürürüz.
          </Typography>
        </li>
      </ul>

      <Typography variant="h4" gutterBottom>
        İletişim
      </Typography>

      <Typography variant="body1" paragraph>
        Siz değerli müşterilerimizle daha yakın bir ilişki kurabilmek adına, her
        zaman sizinle iletişimde olmaktan memnuniyet duyarız. Herhangi bir
        sorunuz, öneriniz veya işbirliği talebiniz varsa, lütfen bize{" "}
        <a href="mailto:borsayalim@hotmail.com">buradan</a> ulaşın.
      </Typography>

      <Typography variant="body1">
        borsayalim.com çözümünü sizlere sunmaktan mutluluk duyuyoruz!
      </Typography>
      <Box
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <Button
          onClick={onMainPageClick}
          sx={{ mt: 2 }}
          variant="contained"
          startIcon={<HomeIcon />}
        >
          Anasayfa
        </Button>
      </Box>
    </Box>
  );
};

export default AboutUs;
