import { Box, Divider, IconButton, Typography } from "@mui/material";
import React from "react";
import { palette } from "../../theme";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMobile } from "../Contexts/MobileContext";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
export default function Footer() {
  // Language settings
  const { t } = useTranslation();
  const mobileInformation = useMobile();

  // Event handler when user clicks social media icons
  const handleSocialMediaClick = (socialMedia: string) => {
    if (socialMedia === "instagram") {
      window.open("https://www.instagram.com/borsayalim/", "_blank");
    } else if (socialMedia === "twitter") {
      window.open("https://www.twitter.com/borsayalim/", "_blank");
    }
  };

  return (
    <Box
      sx={{
        mt: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: palette.black_light,
      }}
    >
      <Divider />
      <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            flex: 1,
            p: 2,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            // backgroundColor: palette.light_gray1,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              color: palette.white,
              fontSize: mobileInformation.isMobile ? "14px" : "18px",
            }}
          >
            borsayalim.com
          </Typography>
          <Link
            to={"/aboutus"}
            style={{ textDecoration: "none", color: palette.white }}
          >
            <Typography
              sx={{
                fontSize: mobileInformation.isMobile ? "10px" : "12px",
                color: palette.white,
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {t("components.ABOUT_US")}
            </Typography>
          </Link>

          <Link
            to={"/privacy"}
            style={{ textDecoration: "none", color: palette.white }}
          >
            <Typography
              sx={{
                fontSize: mobileInformation.isMobile ? "10px" : "12px",
                color: palette.white,
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {t("components.PRIVACY_POLICY")}
            </Typography>
          </Link>
          <a
            style={{ textDecoration: "none" }}
            href="mailto:borsayalim@hotmail.com"
          >
            <Typography
              sx={{
                fontSize: mobileInformation.isMobile ? "10px" : "12px",
                color: palette.white,
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {t("components.REACH_US")}
            </Typography>
          </a>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: mobileInformation.isMobile ? "8px" : "12px",
              color: palette.white,
            }}
          >
            {t("components.ALL_RIGHTS_RESERVED")}
          </Typography>
          <Typography
            sx={{
              fontSize: mobileInformation.isMobile ? "8px" : "12px",
              color: palette.white,
            }}
          >
            2024
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            "@media screen and (max-width: 300px)": {
              flex: 0.5,
            },
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "end",
            px: mobileInformation.isMobile ? 1 : 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box>
              <IconButton onClick={() => handleSocialMediaClick("instagram")}>
                <InstagramIcon
                  sx={{
                    color: palette.white,
                    fontSize: mobileInformation.isMobile ? "24px" : "32px",
                    mr: !mobileInformation.isMobile && 1,
                  }}
                />
              </IconButton>
            </Box>
            <Box>
              <IconButton onClick={() => handleSocialMediaClick("twitter")}>
                <TwitterIcon
                  sx={{
                    color: palette.white,
                    fontSize: mobileInformation.isMobile ? "24px" : "32px",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
